<template>
    <div class="app-main page-container page-component">
        <h1 class="font-60 mg-y-less text-center"><span class="color-danger">Ex</span><span class="color-primary">SiM</span></h1>
        <h2 class="font-56 mg-y-less text-center color-warning"><i class="el-icon-trophy-1"></i> Leader board</h2>
        <el-row type="flex" justify="center">
            <el-col :span="18">
                <div class="mg-y-5">
                    <a href="/select-mode" class="color-lighter font-14"><i class="el-icon-back"></i> ย้อนกลับ</a>
                </div>
                <el-table
                    empty-text='No Data'
                    :data="userRanking"
                    :row-class-name="tableRowClassName"
                    v-loading="loading"
                    style="width: 100%">
                    <el-table-column
                        prop="_id"
                        label="Rank"
                        width="250"
                        header-align="center"
                        align="center">
                        <template slot-scope="scope" >
                            <span v-if="rank !== '' && scope.row.userId === user.userId" :class="{'color-success':scope.row.userId === user.userId}">{{rank}}</span>
                            <span v-else :class="{'color-success':scope.row.userId === user.userId}">{{scope.$index+1}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="groupName"
                        label="Players"
                        header-align="center">
                        <template slot-scope="scope">
                            <span :class="{'color-success':scope.row.userId === user.userId}">{{scope.row.groupName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="summary"
                        label="Score"
                        width="300"
                        header-align="center"
                        align="right">
                        <template slot-scope="scope">
                            <span :class="getColorSummary(scope.row.summary,scope.row.userId)">{{scope.row.summary | comma}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <p></p>
    </div>
</template>

<script>
import DefaultTemplate from '@/template/DefaultTemplate'
import {HTTP} from'@/service/axios'
import {alertFailError,alertCatchError} from '@/service/alertMsg';

export default {
    components:{
        DefaultTemplate
    },
    computed: {
        user(){
            return this.$store.state.user
        }
    },
    data() {
        return {
            userRanking:[],
            rank:'',
            loading:false
        }
    },
    mounted() {
        this.loading = true
        this.fetchRanking()
    },
    methods: {
        fetchRanking(){
            HTTP.get(`/get/ranking/${20}/${this.user.userId}`)
            .then(res=>{
   
                if(res.data.success){
                  
                   this.userRanking = res.data.obj.worldRanking
                   let filter = res.data.obj.worldRanking.filter(filter => filter.userId === this.user.userId)
                   if(filter.length === 0){
                       if(typeof res.data.obj.detail !== 'undefined'){
                           this.userRanking.push(res.data.obj.detail)
                       }
                       
                       this.rank = res.data.obj.rank
                   }
                }else{
                    this.alertFailError()
                }
            })
            .catch((e)=>{
                this.alertCatchError()
                console.log("sentToSo error",e)
            })
            .finally(()=>{
                setTimeout(()=>{
                    this.loading = false
                },500)
                
            })
        },
        tableRowClassName({row, rowIndex}) {
            if (row.userId === this.user.userId) {
                return 'background-color-table';
            } 
            return '';
        },
        getColorSummary(summary,userId){
            if(summary < 0){
                return 'color-danger'
            }else if(summary > userId === this.user.userId){
                return 'color-success'
            }
        }
    },
}
</script>

<style scoped>

</style>